import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useField,
  useTextField
} from "@/components/FormBuilder/Helpers";
import {
  validateIsValidLicenseDate,
  validateLicenseDateIsNotInPast
} from "@/helpers";

export const license = (isRendered = true): FormBlock[] =>
  isRendered
    ? [
        ...useFormSection(
          [
            ...useFormFieldGroup(
              [
                useTextField({
                  key: "licenseNumber",
                  label: __getText("fields", "licenseNumberLabel"),
                  placeholder: __getText("fields", "licenseNumberLabel"),
                  required: true,
                  validations: [
                    {
                      method: "minLength",
                      value: 3,
                      error: __getText(
                        "fields",
                        "licenseNumberMinLengthValidateError"
                      )
                    }
                  ]
                }),
                useField({
                  key: "licenseNumberExpirationDate",
                  label: __getText(
                    "fields",
                    "licenseNumberExpirationDateLabel"
                  ),
                  required: true,
                  type: "date",
                  validations: [
                    {
                      method: "custom",
                      error: "License number is expired",
                      customValidator: (value: any) => {
                        return validateLicenseDateIsNotInPast(value);
                      }
                    }
                  ],
                  conditions: {
                    and: [
                      {
                        field: "licenseNumber",
                        value: "",
                        operator: "not"
                      }
                    ]
                  },
                  pickerOptions: {
                    disabledDate: (fieldValue: Date) => {
                      return validateIsValidLicenseDate(fieldValue);
                    }
                  }
                })
              ],
              { layout: "2-col" }
            )
          ],
          {
            title: "License Information",
            description: "Basic information about agent's license"
          }
        )
      ]
    : [];
