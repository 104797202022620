
import Vue from "vue";
import { userMapGetters, userMapMutations } from "@/store/modules/user";
import { UserFields } from "@/store/modules/user/types";
import { ICompany } from "@/store/modules/companies/types";
import { sameAsPhysicalAddressChangedHandler } from "@/helpers/isSameAsPhysicalAddress";
import {
  agencyMapMutations,
  agencyMapState,
  agencyMapGetters,
  agencyMapActions
} from "@/store/modules/agency";
import { IAgency } from "@/store/modules/agency/types";
import { excludedCompanies } from "@/helpers/companies";
import {
  getUserRoleOptions,
  isExemptedUserRole,
  validateLicenseDateIsNotInPast
} from "@/helpers";
import { get, isEqual } from "lodash";
import { user } from "@/strings/user";
interface IData {
  formElement: any;
  agencyId: string;
  errorMessage: string;
  clearedCompanies: boolean;
  defaultAtlasAdmin: { department: string; title: string };
}

interface IDynamicOptions {
  label: string;
  value: string;
}

export default Vue.extend({
  name: "UserAddNewFields",
  props: {
    selectAllCompanies: {
      type: Boolean,
      required: false,
      default: false
    },
    addNewUserFields: {
      type: Array,
      default: () => []
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isUserBanned: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  mounted() {
    this.formElement = this.$refs.formElement;
    const editingUserRole = get(this.getEditingUser, "role", "");

    this.editField({
      key: "isAtlasAdmin",
      value: isExemptedUserRole(editingUserRole)
    });
    if (this.initWithAgencyId) {
      this.$emit("disableAgencyField");
      this.editField({
        key: "role",
        value: "agent"
      });
      this.editField({
        key: "agencyId",
        value: this.initWithAgencyId
      });
      this.editField({
        key: "disabledPermissions",
        value: ["agent_reports:read"]
      });
    }
    if (!this.getEditingUser?.displayName) {
      this.editField({
        key: "displayName",
        value:
          this.getEditingUser?.firstName + " " + this.getEditingUser?.lastName
      });
    }
    if (
      this.getEditingUser &&
      this.getEditingUser.isAtlasAdmin &&
      !this.getEditingUser.atlasAdmin
    ) {
      this.editField({
        key: "atlasAdmin",
        value: this.defaultAtlasAdmin
      });
    }
  },
  watch: {
    ["getEditingUser.firstName"](newVal, oldVal) {
      if (this.getEditingUser) {
        const { displayName, lastName } = this.getEditingUser;
        if (!displayName || displayName === `${oldVal} ${lastName}`) {
          this.editField({
            key: "displayName",
            value: `${newVal} ${lastName}`
          });
        }
      }
    },
    ["getEditingUser.lastName"](newVal, oldVal) {
      if (this.getEditingUser) {
        const { displayName, firstName } = this.getEditingUser;
        if (!displayName || displayName === `${firstName} ${oldVal}`) {
          this.editField({
            key: "displayName",
            value: `${firstName} ${newVal}`
          });
        }
      }
    },
    ["getEditingUser.agencyId"]: {
      handler(newVal) {
        this.agencyId = newVal;

        this.handleCompanyAutoSelect(this.agencyId);
      },
      immediate: true
    },
    ["getEditingUser.underWriterAgencies"]: {
      handler(newVal, oldVal) {
        if (
          newVal &&
          oldVal &&
          newVal.length !== oldVal.length &&
          this.getEditingUser?.role === "underwriting"
        ) {
          this.editField({
            key: "companyIds",
            value: []
          });
        }
      },
      immediate: true
    },
    ["getEditingUser.licenseNumberExpirationDate"]: {
      handler(value: any) {
        //if license date is in past  alert user error
        const licenseNumberExpirationDate: any = value;
        const role = get(this.getEditingUser as any, "role");
        if (
          ["agencyAdmin", "subAdmin", "agent"].includes(role) &&
          licenseNumberExpirationDate &&
          !validateLicenseDateIsNotInPast(licenseNumberExpirationDate)
        ) {
          this.errorMessage = "Agent License number is expired";
        } else {
          this.errorMessage = "";
        }
      },
      immediate: true
    },
    ["getEditingUser.role"]: {
      handler(newValue) {
        if (!["agent", "agencyAdmin", "subAdmin", "admin"].includes(newValue)) {
          this.editField({
            key: "companyIds",
            value: []
          });
          this.clearedCompanies = true;
        } else {
          this.clearedCompanies = false;
        }
      }
    }
  },
  data(): IData {
    return {
      formElement: {},
      agencyId: "",
      errorMessage: "",
      clearedCompanies: false,
      defaultAtlasAdmin: {
        department: "",
        title: ""
      }
    };
  },
  methods: {
    ...agencyMapMutations({ setAgencies: "SET_STATE" }),
    ...userMapMutations({
      editField: "SET_USER_EDIT_FIELD",
      removeProperty: "REMOVE_PROPERTY_FROM_STORE"
    }),
    ...agencyMapActions(["getAgencies"]),
    formFieldChangeHandler({
      key,
      value
    }: {
      key: UserFields;
      value: any;
    }): void {
      const addressKeys = ["physicalAddress", "mailingAddress"];
      if (addressKeys.includes(key)) {
        const { fieldKey, value: fieldValue } = value;
        if (fieldKey.includes("isSameAsPhysicalAddress")) {
          sameAsPhysicalAddressChangedHandler(
            key,
            fieldValue,
            this.getEditingUser?.physicalAddress || {},
            this.editField
          );
        }
        this.editField({
          key: `${key}.${fieldKey}` as any,
          value: fieldValue
        });
        if (
          key === "physicalAddress" &&
          this.getEditingUser &&
          this.getEditingUser.mailingAddress &&
          this.getEditingUser.mailingAddress.isSameAsPhysicalAddress
        ) {
          this.editField({
            key: `mailingAddress.${fieldKey}` as any,
            value: fieldValue
          });
        }
      } else if (key === "agencyId") {
        this.agencyId = value;
        const agency = this.getAgencyById(this.agencyId);
        this.editField({
          key,
          value
        });
        if (this.getEditingUser?.role == "agent") {
          if (agency && agency.physicalAddress) {
            this.editField({
              key: `physicalAddress` as any,
              value: agency?.physicalAddress
            });
          }
          if (agency && agency.mailingAddress) {
            this.editField({
              key: `mailingAddress` as any,
              value: agency?.mailingAddress
            });
          }
        }
        this.editField({
          key: "allAgencyCodes" as any,
          value: []
        });
      } else if (key === "role") {
        if (!isExemptedUserRole(value)) {
          this.removeProperty("isAtlasAdmin");
          this.removeProperty("atlasAdmin");
        } else {
          this.editField({
            key: `isAtlasAdmin`,
            value: isExemptedUserRole(value)
          });
          this.editField({
            key: `atlasAdmin`,
            value: this.defaultAtlasAdmin
          });
        }
        if (this.isNew) {
          let disabledPermissions: string[] = [];
          if (value === "agent" || value === "subAdmin") {
            disabledPermissions = ["agent_reports:read"];
          }
          if (value === "ezlynx") {
            this.editField({
              key: "disabledPermissions",
              value: [
                "documents:read",
                "notifications:read",
                "agent_reports:read"
              ]
            });
            this.editField({
              key: "companyIds",
              value: []
            });
            this.editField({
              key: "agencyId",
              value: ""
            });
          }
          this.editField({
            key: "disabledPermissions",
            value: disabledPermissions
          });
        }

        const isAgentOrAgencyAdmin =
          value === "agent" || value === "agencyAdmin" || value === "subAdmin";
        if (!isAgentOrAgencyAdmin) {
          this.editField({
            key: "agentCode",
            value: ""
          });
          this.editField({
            key: "allAgencyCodes",
            value: []
          });
          this.editField({
            key: "licenseNumber",
            value: ""
          });
          this.editField({
            key: "licenseNumberExpirationDate",
            value: ""
          });
          if (value === "adjuster") {
            this.editField({
              key: "agencyId",
              value: ""
            });
            this.editField({
              key: "disabledPermissions",
              value: [
                "documents:read",
                "notifications:read",
                "agent_reports:read"
              ]
            });
          }
        }
        if (value !== "adjuster") {
          this.editField({
            key: "adjusterCode",
            value: ""
          });
          this.editField({
            key: "adjustingFirm",
            value: ""
          });
        }
        this.editField({ key, value });
      } else if (key === "licenseNumberExpirationDate") {
        if (validateLicenseDateIsNotInPast(value)) {
          this.errorMessage = "";
        }
        this.editField({
          key,
          value
        });
      } else if (key === "allAgencyCodes") {
        const allValidAgencyCodes = this.agencyCodeOptions
          .map(({ value }) => value)
          .filter(
            (agencyCode: string) =>
              agencyCode !== user.selectAllAgencyCodesLabel
          );

        if (
          this.getEditingUser &&
          ["agent", "agencyAdmin", "subAdmin"].includes(
            this.getEditingUser.role
          )
        ) {
          this.editField({
            key: "agentCode",
            value:
              value[0] === user.selectAllAgencyCodesLabel
                ? allValidAgencyCodes[0]
                : value[0]
          });
        }
        if (value && value.includes(user.selectAllAgencyCodesLabel)) {
          this.editField({ key, value: allValidAgencyCodes });
        } else this.editField({ key, value });
      } else {
        this.editField({
          key,
          value
        });
      }

      // pass on events since this component wraps formBuilder
      this.$emit("formFieldChanged", {
        key,
        value
      });
    },
    validationChangeHandler($event: any): void {
      // pass on events since this component wraps formBuilder
      this.$emit("validationChanged", $event);
    },
    handleFieldClickFieldArea(value: any) {
      if (value.key === "agencyId") {
        const routeData = this.$router.resolve({
          path: `/admin/agencies/${this.getEditingUser?.agencyId}/edit`
        });
        window.open(routeData.href, "_blank");
      }
    },
    setCompanies(agencyId: string | undefined) {
      const newCompanyIds =
        this.selectAllCompanies && agencyId != ""
          ? this.companyOptions.map((v: any) => v.value)
          : [];
      this.editField({
        key: "companyIds",
        value: newCompanyIds
      });
    },
    handleCompanyAutoSelect(agencyId: string) {
      if (
        !this.clearedCompanies &&
        !isEqual(agencyId, this.getSavedUser.agencyId) &&
        this.getEditingUser?.role !== "underwriting"
      ) {
        this.setCompanies(agencyId);
      }
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser", "getUserById"]),
    ...agencyMapState(["agencies"]),
    ...agencyMapGetters(["getAgencyById"]),
    getSavedUser(): any {
      if (!this.getEditingUser) return {};
      const { _id } = this.getEditingUser;
      return _id ? this.getUserById(_id) : {};
    },
    companyOptions(): IDynamicOptions[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      const agencyId = this.getEditingUser?.agencyId || this.initWithAgencyId;
      if (
        this.getEditingUser &&
        ["agent", "agencyAdmin", "subAdmin", "admin"].includes(
          this.$getCurrentUser?.role
        ) &&
        agencyId
      ) {
        const agency = this.getAgencyById(this.agencyId);
        if (agency) {
          return companies
            .filter(
              (company: any) =>
                agency?.companyIds.includes(company._id) &&
                !excludedCompanies.includes(company.companyNumber)
            )
            .map((company: ICompany) => ({
              label: company.companyName,
              value: company._id
            }))
            .sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
        }
      }

      if (
        this.getEditingUser &&
        this.getEditingUser.role === "underwriting" &&
        this.getEditingUser.underWriterAgencies
      ) {
        if (
          this.getEditingUser.underWriterAgencies.every(
            (value: any) => typeof value === "string"
          )
        ) {
          const agencies = this.agencyWithSubAgencies.filter((agency: any) => {
            return this.getEditingUser?.underWriterAgencies?.includes(
              agency._id
            );
          });
          let allCompanyIds = agencies?.map((agency: any) => [
            ...agency.companyIds
          ]);
          allCompanyIds = allCompanyIds?.flat();
          const companyIds = [...new Set(allCompanyIds)];

          return companies
            .filter(
              (company: any) =>
                companyIds.includes(company._id) &&
                !excludedCompanies.includes(company.companyNumber)
            )
            .map((company: ICompany) => ({
              label: company.companyName,
              value: company._id
            }))
            .sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
        }
      }

      if (this.getEditingUser && this.getEditingUser.role === "admin") {
        return companies
          .filter(
            (company: ICompany) =>
              !excludedCompanies.includes(company.companyNumber)
          )
          .map((company: ICompany) => ({
            label: company.companyName,
            value: company._id
          }))
          .sort((a: any, b: any) => (a.label < b.label ? -1 : 1));
      }

      return [];
    },
    agencyWithSubAgencies(): IAgency[] {
      const agency = this.getAgencyById(this.agencyId);
      if (
        agency &&
        Object.keys(agency).length &&
        agency.childAgencies &&
        agency.childAgencies.length
      ) {
        return [agency, ...agency.childAgencies];
      }
      return [];
    },
    agencyCodeOptions(): IDynamicOptions[] {
      let agencyCodes: IDynamicOptions[] = [];
      const agency = this.getAgencyById(this.agencyId);
      if (agency && agency.agencyCode) {
        const agencyCodeOptions = {
          label: agency.agencyCode,
          value: agency.agencyCode
        };
        agencyCodes.push(agencyCodeOptions);
        const subAgencies = agency?.childAgencies;

        if (subAgencies && Array.isArray(subAgencies) && subAgencies.length) {
          const subAgencyCodeOptions = subAgencies.map(agency => {
            return {
              label: agency.agencyCode,
              value: agency.agencyCode
            };
          });
          agencyCodes = [...agencyCodes, ...subAgencyCodeOptions];
        }
      }
      if (agencyCodes && agencyCodes.length) {
        agencyCodes.unshift({
          label: "Select all agency codes",
          value: user.selectAllAgencyCodesLabel
        });
      }
      return agencyCodes;
    },
    agentCodeOptions(): IDynamicOptions[] {
      if (this.getEditingUser && this.getEditingUser.allAgencyCodes) {
        return this.getEditingUser.allAgencyCodes.map((agencyCode: string) => {
          return { label: agencyCode, value: agencyCode };
        });
      } else {
        return [];
      }
    },
    roleOptions(): IDynamicOptions[] {
      const currentUserRole = get(this.$getCurrentUser, "role", "");
      return getUserRoleOptions(currentUserRole);
    },
    underWriterAgencies(): IDynamicOptions[] {
      const agencies = this.getEditingUser?.underWriterAgenciesData
        ?.map((agency: IAgency) => ({
          label: `${agency.agencyCode} - ${agency.agencyName}`,
          value: agency._id
        }))
        .sort(function(
          firstAgency: { label: string; value: string },
          secondAgency: { label: string; value: string }
        ) {
          return firstAgency.label.localeCompare(secondAgency.label);
        });
      return agencies as IDynamicOptions[];
    },
    initWithAgencyId(): string | (string | null)[] {
      return this.$route.query.agencyId;
    }
  }
});
