var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"user-edit__fields"}},[_c('custom-alert',{attrs:{"title":"Notice!","message":_vm.errorMessage,"show":!!_vm.errorMessage,"type":"danger"},on:{"dismiss":function($event){_vm.errorMessage = ''}}}),_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.addNewUserFields,"formValues":_vm.getEditingUser || {},"dynamicOptions":{
      companyIds: _vm.companyOptions,
      role: _vm.roleOptions,
      allAgencyCodes: _vm.agencyCodeOptions,
      agentCode: _vm.agentCodeOptions,
      underWriterAgencies: _vm.underWriterAgencies
    },"disabled":_vm.$isCurrentUserUnderwriter ||
        _vm.$isCurrentUserClaims ||
        _vm.$isCurrentUserBilling ||
        _vm.$isCurrentUserDataEntry ||
        _vm.isUserBanned},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":_vm.validationChangeHandler,"clickFieldArea":_vm.handleFieldClickFieldArea}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }